:root {

  --bodybg: #f0eff3;
  --txt-white: #fff;
  --txt-black: #000;
  // --primary-color: #31bafd;
  --primary-color: #24a4e3;
  --border-color: #e1e5eb;
  --sidebar-bg: #fff;
  --sidebar-border-new: rgba(255, 255, 255, 0.15);
  --sidebar-hover: #f0f2f6;
  // --arrow-img: url(../Image/down_2.png);
  --arrow-img: url(../Image/arrow_downward.png);


  //harpreet
  --btn-hover: #404eb1;
  --link-bg: #1f1f2163;
  --btn-bgh: #ebebeb;

  //nishu
  --light-blue: #31bafd1f;
  --border-color: #d1d1d263;
  --txt-light: #000;
  --sky-blue: #00abff;
  --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --gradient: linear-gradient(to right, #00a8fab0 60%, #016498);
  --team-bg: #31bafd1f;


  // ? dc saab 

  --card-new-df: #fff;
  --card-inner-df: #ffffff3b;
  --new-card-bg:#fff;
  --new-small-inner-card:#f5f5f5;


  // ravikant

  --new_border_color: #59595921;
  --new_card_bg: rgb(33 159 216 / 8%);
}
