.support_main {
  margin-top: 30px;

  .support_inner {
    padding: 20px;
    background: var(--new_card_bg);
    border: 1px solid var(--new_border_color);
    border-radius: 10px;

    .send_sec {
      text-align: center;
      margin-top: 20px;

      .send {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .top_head_sec {
      h4 {
        color: var(--primary-color);
        padding: 15px;
        background: var(--light-blue);
        border: 1px solid var(--new_border_color);
        border-radius: 10px;
      }
    }

    .support-section {
      margin-top: 30px;

      .select_reason {
        .form-select {
          background-image: var(--arrow-img);
          background: var(--new_card_bg);
          border: 1px solid var(--new_border_color);
          color: var(--txt-black);
        }
      }

      .desc {
        .text_sec {
          width: 100%;
          background: var(--new_card_bg);
          border: 1px solid var(--new_border_color);
          border-radius: 10px;
          padding: 10px;
          color: var(--txt-black);

          &::placeholder {
            color: var(--txt-black);
          }

          &:focus-visible {
            outline: none;
          }
        }
       
      }

      .form-select {
        padding: 10px;

        &:focus {
          box-shadow: none;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .support_row {
      flex-direction: row-reverse;
      .desc{
        .type-choose{
          width: 100%;
          background: #d4e2f0;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid #c5d1de;
        }
      }

      .right_sec_support {
        padding: 20px;
        text-align: center;

        @media ($breakpoint_SM) {
          padding: 20px 0px;
        }

        h4 {
          padding-bottom: 20px;
          color: var(--txt-black);
        }

        p {
          background: var(--bodybg);
          border-radius: 10px;
          padding: 10px;
          color: var(--sky-blue);

          @media ($breakpoint_ESM) {
            font-size: 14px;
          }
        }

        .question {
          width: 54px;
        }
      }

      .upload_document {
        padding: 20px 0px;
        text-align: center;

        .main {
          display: flex;
          justify-content: center;
          padding: 0px 15px;
          flex-wrap: wrap;
          gap: 40px;
          background: var(--new_card_bg);
          border: 1px solid var(--new_border_color);
          box-shadow: var(--box-shadow);
          border-radius: 10px;

          @media ($breakpoint_LG) {
            gap: 20px;
            justify-content: center;
          }

          p {
            margin: 0px;
            color: var(--txt-black);

            @media ($breakpoint_ESM) {
              font-size: 14px;
            }
          }

          .upload-input {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 20px 0;
            align-items: center;

            .browse {
              background: transparent;
              border: 1px solid var(--sky-blue);
              color: var(--primary-color);
              cursor: pointer;
            }

            img {
              width: 100%;
              // border-radius: 15px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
