.profile1-section {
  margin-top: 30px;
  // border-radius: 10px;

  @media ($breakpoint_MD) {
    .react-tabs {
      .row {
        gap: 20px;
      }
    }
  }

  .personal-info {
    background-color: var(--new_card_bg);
    padding: 20px;
    border-radius: 10px;
    // box-shadow: var(--box-shadow);
    height: 100%;
    border: 1px solid var(--new_border_color);

    .my-info {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid var(--sky-blue);
      padding-bottom: 15px;
      margin-bottom: 15px;

      b {
        // background-color: var(--primary-color);
        color: var(--primary-color);
        border-radius: 16px;
        padding: 8px 16px;
      }
    }

    .profile-infos {
      .name-pic {
        text-align: center;
        background: var(--primary-color);
        padding: 10px;
        border-radius: 10px;

        .outer_profile {
          padding: 10px;
          display: flex;
          justify-content: center;
          border-radius: 50%;

          .left_profile {
            width: 120px;
            border: 2px dashed var(--sky-blue);
            height: 120px;
            padding: 8px;
            background: var(--bodybg);
            border-radius: 50%;
            position: relative;

            .emptyPlaceholder {
              cursor: pointer;
              position: absolute;
              top: 37px;

              left: 21px;

              h6 {
                font-size: 12px;
                margin-top: 5px;
                color: var(--txt-black);
              }

              svg {
                width: 25px;
                fill: var(--txt-light);
              }
            }
          }
        }

        .owner-pic {
          margin: 10px 0px;

          img {
            background: var(--light-blue);
            border-radius: 50%;
            padding: 7px;
            width: 82px;
          }
        }

        .name-post {
          h5 {
            color: var(--txt-white);

            font-weight: 600;
            margin: 0;
          }

          h6 {
            color: var(--txt-light);

            margin-bottom: 10px;
          }
        }
      }

      .profile-follow {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;

        .follows-btn {
          border: none;
          background-color: var(--primary-color);
          color: var(--txt-white);
          padding: 10px 30px;
          border-radius: 10px;
          font-size: 16px;
        }

        .message-btn {
          border: 1px solid var(--primary-color);
          background-color: var(--txt-white);
          color: var(--txt-black);
          padding: 10px 30px;
          border-radius: 10px;
          font-size: 16px;
        }
      }
    }

    .bio {
      margin-bottom: 20px;
      // border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      padding: 15px 0;

      b {
        font-size: 20px;
        font-weight: 400;
        color: var(--primary-color);
      }

      p {
        font-size: 16px;
        margin: 0;
        padding: 12px 0;
        color: var(--txt-light);
      }
    }

    .react-tabs__tab-list {
      border: none;

      .react-tabs__tab {
        border-right: 3px double transparent;
        border-left: 3px solid transparent;
        border-radius: 0px;
        display: block !important;
        color: var(--sky-blue);
        margin-bottom: 5px;
      }

      .react-tabs__tab--selected {
        border-right: 3px double var(--sky-blue);
        border-left: 3px solid var(--sky-blue);
        border-top: 0px;
        border-radius: 0px;
        border-color: var(--sky-blue);
        background: var(--bodybg);
        color: var(--primary-color);

        &::after {
          display: none;
        }
      }
    }

    .contact-info {
      b {
        font-size: 20px;
        font-weight: 400;
        color: var(--primary-color);
      }

      .contact-details {
        margin-top: 20px;

        .emails {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          h6 {
            color: var(--txt-light);
            font-size: 17px;
          }

          p {
            font-size: 16px;
            margin: 0;
            color: var(--txt-black);
          }
        }
      }
    }

    .foot-info {
      margin: 45px 0 30px;

      .down-btn {
        padding: 8px 16px;
        color: var(--txt-white);
        background-color: var(--primary-color);
        border: none;
        outline: none;
        border-radius: 8px;
      }
    }
  }

  .account1-details {
    background-color: var(--new_card_bg);
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    border: 1px solid var(--new_border_color);

    // box-shadow: var(--box-shadow);

    &::after {
      content: "";
      position: absolute;
      height: 60%;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: var(--primary-color);
      background-position: top;
      background-size: cover;
      z-index: -1;
      border-radius: 10px;
      opacity: 0.7;
    }

    .accout-seting {
      background: var(--sidebar-bg);
      padding: 20px;
      border-radius: 10px;

      min-height: 460px;

      .bottom_sec {
        .form {
          #address {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            background: var(--bodybg);
            margin-bottom: 20px;
            color: var(--txt-black);

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: var(--txt-black);
            }
          }
        }
      }

      .form {
        margin-bottom: 20px;

        .form-label {
          font-size: 15px;

          color: var(--txt-black);
        }

        input {
          background-color: var(--bodybg);
          line-height: 2.5;
          font-size: 14px;
        }
      }

      h2 {
        margin-bottom: 20px;
        color: var(--primary-color);
      }

      .country_part {
        .form-select {
          padding: 11px;
          border: 1px solid var(--border-color);
          background-color: var(--bodybg) !important;
          background-image: var(--arrow-img);
          color: var(--txt-black);

          &:focus {
            box-shadow: none;
          }
        }
      }

      .form-part {
        display: flex;
        align-items: center;
        gap: 10px;

        @media ($breakpoint_MD) {
          display: block;
        }

        .form {
          margin-bottom: 20px;

          .form-label {
            font-size: 15px;
            color: var(--txt-black);
          }
          .css-t3ipsp-control,
          .css-13cymwt-control {
            position: relative;
            top: 5px;
            background-color: var(--bodybg);
            border: 0;
            border: 1px solid var(--border-color) !important;
            border-radius: var(--bs-border-radius) !important;
          }

          .css-1fdsijx-ValueContainer {
            padding: 6px 8px;
          }
          .css-1u9des2-indicatorSeparator {
            background-color: hsl(0deg 2.51% 20.52%) !important;
          }

          .css-1nmdiq5-menu {
            // background-color: #191616;
            background-color: var(--bodybg);
            overflow: hidden !important;

            #react-select-3-listbox {
              color: #000 !important;
            }
          }

          .css-1dimb5e-singleValue,
          .css-qbdosj-Input {
            color: #000 !important;
          }

          #react-select-3-listbox::-webkit-scrollbar {
            width: 5px;
            background-color: #dddddd62 !important;
          }
          #react-select-3-listbox::-webkit-scrollbar-thumb {
            background-color: var(--primary-color) !important;
          }
        }
      }

      .form-part2 {
        label {
          margin-bottom: 0.5rem;
          color: var(--txt-black);
        }

        .hide-pass {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 40px;

          .form-control {
            &:-webkit-autofill {
              background-color: var(--bodybg) !important;
              -webkit-text-fill-color: var(--txt-black);
              -webkit-box-shadow: 0 0 0px 1000px var(--bodybg) inset;
            }

            &:focus {
              box-shadow: 0 0 0px 1000px var(--bodybg) inset;
              border: 1px solid var(--border-color);
              background-color: var(--bodybg);
            }

            &:focus-visible {
              outline: none;
            }
          }

          button {
            border: none;
            font-size: 16px;
            position: absolute;
            background: none;
            right: 10px;
            top: 10px;

            &:focus {
              outline: none;
            }

            svg {
              font-size: 20px;
              color: var(--txt-black);
            }
          }
        }
      }

      .form-part3 {
        .authorize {
          .author-head {
            background-color: var(--primary-color);
            padding: 15px 0 15px 15px;
            color: var(--txt-white);
            border-radius: 7px 7px 0 0;
            margin-bottom: 30px;

            h4 {
              font-size: 16px;
              margin: 0;
            }
          }

          p {
            font-size: 16px;
            margin-bottom: 40px;
          }

          .scan_sec {
            display: flex;
            margin-top: 30px;

            .store-pic {
              align-self: center;

              img {
                width: 60%;
              }
            }

            .link1-copy {
              margin-bottom: 15px;

              input {
                font-size: 14px;
                margin-bottom: 0px;
                padding: 10px;
                border-radius: 5px 0 0 5px;
                border: 1px solid var(--border-color);
                width: 85%;
              }

              .icon1-copy {
                // margin-left: 12px;
                padding: 5px 11px;

                border-radius: 0 5px 5px 0;
                border-left: none;

                svg {
                  font-size: 32px;
                  margin-left: 10px;
                  color: var(--sky-blue);
                }
              }
            }
          }

          .gogle-key {
            text-align: center;
          }
        }
      }

      // .button_style{
      //   width: 50%;
      // }
    }

    .account-foot {
      text-align: end;
      margin: 55px 0 30px;

      .up-btn {
        padding: 8px 16px;
        color: var(--txt-white);
        background-color: var(--primary-color);
        border: none;
        outline: none;
        border-radius: 8px;
      }
    }

    .react-tabs__tab--selected {
      background-color: var(--primary-color);
      border: none;
      color: var(--txt-white);
    }
  }
}

.react-tabs__tab-list {
  overflow: hidden;
}
