.main-tree {

    $color: #222;
    $border-color: #ddd;
    $background-color: #eee;
    $border-weight: 1px;
    $margin: 12px;
    // overflow: scroll;
    // width: 800px;



.card-body{
    // overflow: scroll;
    // margin: 0px auto;
    // width: 10000px !important;
    // height: 100%;

    @media(max-width:1300px){
        overflow: scroll;
    }
}


   .inner-team{
    // width: 900px;
    // overflow: scroll;
   }
    

    .admin-img {
        text-align: center;

        .img-sec {
            width: 50px;
            height: 50px;
        }

    }

    // new section.........

    .user-main-sec {
        text-align: center;

        &:hover{
            .dropdown-sec{
                display: block;
                width: 250px;
            }

        }
     

        .user {
            text-align: center;
            cursor: pointer;

            .user-img {

                .img-sec {
                    width: 50px;
                    height: 50px;
                }
            }

        }

        .dropdown-sec {
            position: absolute;
            // background-color: $background-color;
            background-color: #fff;
            padding: 10px 5px;
            top: 179px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            z-index: 11;
            border-radius: 10px;
            transition: all 0.4s linear;
            display: none;
            box-shadow: 0px 0px 10px 0px #ddd;

            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 47%;
                transform: translate(-50%, -50%);
                width: 40px;
                height: 20px;
                border-bottom: solid 20px #fff;
                border-left: solid 20px transparent;
                border-right: solid 20px transparent;
            }

            p{
                margin-bottom: 8px;
                font-size: 14px;
                white-space: nowrap;

                span{
                    color: var(--primary-color);
                }
            }

        }

    }



    .tree {


        margin: $margin * 1.5;
        padding: 0;

        &:not(:empty):before,
        &:not(:empty):after,
        ul:not(:empty):before,
        ul:not(:empty):after,
        li:not(:empty):before,
        li:not(:empty):after {
            display: block;
            position: absolute;
            content: "";
            
        }

        ul,
        li {
            position: relative;
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
        }

        li>div {
            background-color: $background-color;
            color: $color;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 10px;

            p {
                margin-bottom: 5px;
                white-space: nowrap;

            }
        }

        &.cascade {
            li {
                margin-left: 2 * $margin;

                div {
                    margin-top: $margin;
                }

                &:before {
                    border-left: $border-weight solid $border-color;
                    height: 100%;
                    width: 0;
                    top: 0;
                    left: -$margin;
                }

                &:after {
                    border-top: $border-weight solid $border-color;
                    width: $margin;
                    left: -$margin;
                    top: $margin * 2;
                }

                &:last-child:before {
                    height: $margin * 2;
                    top: 0;
                }
            }

            &>li {

                &:first-child:before {
                    top: $margin * 2;
                }

                &:only-child {
                    margin-left: 0;
                }

                &:only-child:before,
                &:only-child:after {
                    content: none;
                }
            }
        }

        &.horizontal {
            li {
                display: flex;
                align-items: center;
                margin-left: $margin * 2;

                div {
                    margin: $margin/2 0;
                }

                &:before {
                    border-left: $border-weight solid $border-color;
                    height: 100%;
                    width: 0;
                    top: 0;
                    left: -$margin;
                }

                &:first-child:before {
                    height: 50%;
                    top: 50%;
                }

                &:last-child:before {
                    height: 50%;
                    bottom: 50%;
                    top: auto;
                }

                &:after,
                ul:after {
                    border-top: $border-weight solid $border-color;
                    height: 0;
                    width: $margin;
                    top: 50%;
                    left: -$margin;
                }

                &:only-child:before {
                    content: none;
                }

                ul:after {
                    left: 0;
                }
            }

            &>li {
                &:only-child {
                    margin-left: 0;
                }

                &:only-child:before,
                &:only-child:after {
                    content: none;
                }
            }
        }

        &.vertical {
            display: flex;
            width: 100%;
            // margin-left: 100px;
            width: 956px;
            overflow: scroll;

            ul {
                display: flex;
             }

            li {
                display: flex;
                flex-direction: column;
                align-items: center;

                div {
                    margin: $margin $margin/2;
                }

                &:before {
                    border-left: $border-weight solid $border-color;
                    height: $margin;
                    width: 0;
                    top: 0;
                }

                &:after {
                    border-top: $border-weight solid $border-color;
                    height: 0;
                    width: 100%;
                }

                &:first-child:after {
                    border-top: $border-weight solid $border-color;
                    height: 0;
                    width: 50%;
                    left: 50%;
                }

                &:last-child:after {
                    border-top: $border-weight solid $border-color;
                    height: 0;
                    width: 50%;
                    right: 50%;
                }

                &:only-child:after {
                    content: none;
                }

                ul:before {
                    border-left: $border-weight solid $border-color;
                    height: $margin;
                    width: 0;
                    top: -$margin;
                    display: none;
                }
            }

            &>li {

                &:only-child:before,
                &:only-child:after {
                    content: none;
                }
            }
        }

        %subcascade {
            flex-direction: column;
            align-items: start;
            padding: 0 $margin;

            &:before {
                left: 2*$margin;
            }

            &:after {
                left: 0;
            }

            &:first-child:after {
                left: 2*$margin;
                width: 100%;
            }

            &:last-child:after {
                left: 0;
                width: 2*$margin;
            }

            ul,
            li {
                display: block;
            }

            ul:before,
            ul:after,
            li:before,
            li:after {
                border: none;
            }

            div {
                margin: 0;
                margin-top: $margin;
            }

            li {
                margin-left: 2 * $margin;

                &:before {
                    border-left: $border-weight solid $border-color;
                    height: 100%;
                    width: 0;
                    top: 0;
                    left: -$margin;
                }

                &:after {
                    border-top: $border-weight solid $border-color;
                    width: $margin;
                    height: 0;
                    left: -$margin;
                    top: $margin * 2;
                    content: "";
                }

                &:last-child:before {
                    height: $margin * 2;
                    top: 0;
                }
            }
        }

        &.vertical .cascade {
            @extend %subcascade;
        }

        &.vertical.cascade-1 {
            &>li {
                @extend %subcascade;
            }
        }

        &.vertical.cascade-2 {
            &>li>ul>li {
                @extend %subcascade;
            }
        }

        &.vertical.cascade-3 {
            &>li>ul>li>ul>li {
                @extend %subcascade;
            }
        }

        &.vertical.cascade-4 {
            &>li>ul>li>ul>li>ul>li {
                @extend %subcascade;
            }
        }
    }
}