.deposit-section {
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;

  .blur {
    filter: blur(5px);
    /* Adjust the blur intensity as needed */
  }

  .color_ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .deposit_inner {
    background-color: var(--new_card_bg);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    border: 1px solid var(--new_border_color);

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-image: url(../Image/wave1.svg);
      z-index: -1;
      opacity: 0.8;
    }

    @include table_styles;

    .deposit-scanner {
      // padding: 40px;
      @media ($breakpoint_MD) {
        text-align: center;
      }

      p {
        color: var(--txt-black);
      }

      .scanner-pic {
        // text-align: center;

        .qr-code {
          height: 160px !important;
          width: 160px !important;
          background: var(--txt-white);
          border-radius: 10px;
          box-shadow: var(--box-shadow);
          padding: 10px;
        }
      }

      p {
        font-size: 15px;
        margin-top: 20px;

        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }

    .deposit-all-data {
      // padding: 40px;
      background: var(--sidebar-bg);
      border: 1px dotted var(--border-color);
      padding: 20px;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 576px) {
        padding: 20px;
      }

      p {
        color: var(--txt-black);
      }

      h5 {
        color: var(--txt-black);
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .link-copy {
        display: flex;
        align-items: center;

        margin-bottom: 20px;

        input {
          font-size: 14px;
          margin-bottom: 0px;
          padding: 10px;
          border-radius: 5px 0 0 5px;
          width: 100%;
          border: 1px solid var(--border-color);
          background: var(--bodybg);
          color: var(--txt-black);

          @media (max-width: 576px) {
            width: 100%;
          }
        }

        .icon-copy {
          background: var(--sky-blue);
          padding: 5px 11px;
          border: 1px solid var(--border-color);
          border-radius: 0 5px 5px 0;
          border-left: none;

          svg {
            font-size: 32px;
            color: var(--txt-white);
          }
        }
      }

      .deposit-links {
        display: flex;
        // flex-direction: column;
        gap: 30px;
        margin-bottom: 10px;

        @media ($breakpoint_LG) {
          gap: 10px;
        }

        @media ($breakpoint_450) {
          display: block;
        }

        a {
          text-decoration: none;

          @media ($breakpoint_450) {
            display: flex;
            margin-bottom: 10px;
          }
        }

        .linking {
          text-decoration: none;
          background-color: var(--link-bg);
          color: var(--txt-white);
          padding: 15px 60px;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 300;
          width: 60%;
          text-align: center;
        }
      }

      p {
        font-size: 16px;
        // margin-left: 10px;
        text-align: left;
        margin-bottom: 30px;

        @media (max-width: 576px) {
          margin-left: 0;
          font-size: 12px;
        }
      }
    }

    .deposit-table {
      margin: 50px 0px;
    }
  }
}
