@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,200;1,9..40,300&family=Lato:wght@300;400;700;900&family=Outfit:wght@200;300;400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&display=swap");

// font-family: 'DM Sans', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Outfit', sans-serif;
// font-family: 'Titillium Web', sans-serif;

$font_heading: "Titillium Web", sans-serif;
$font_pragph: "DM Sans", sans-serif;
$font_btn: "Outfit", sans-serif;

//Breakpoints....
$breakpoint_XXXL: "max-width: 1500px";
$breakpoint_XXL: "max-width: 1400px";
$breakpoint_XL: "max-width: 1200px";
$breakpoint_LG: "max-width: 992px";
$breakpoint_MD: "max-width: 768px";
$breakpoint_SM: "max-width: 576px";
$breakpoint_450: "max-width: 450px";
$breakpoint_ESM: "max-width: 385px";
$breakpoint_50: "max-width: 350px";

$sidebar_width: 300px;
$body: var(--bodybg);

body {
  background-color: $body;
}

main {
  min-height: calc(100vh - 50px);
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  overflow: hidden;
  // padding: 85px 30px 100px 30px;
  padding: 0px 30px 100px 30px;
  margin-left: 295px;

  @media ($breakpoint_XL) {
    margin: 0;
    padding: 0;
    padding: 0px 0.85rem 0 0.85rem !important;
  }

  @media ($breakpoint_LG) {
    margin: 0 auto;
  }

  @media ($breakpoint_MD) {
    margin-left: 0;
    min-width: 100%;
  }
}

.button_style {
  padding: 7px 15px;
  color: var(--txt-white);
  border-radius: 24px;
  border: none;
  font-size: 14px;
  background: var(--gradient);
  outline: none;
  text-decoration: none;
  white-space: nowrap;
}

.coustom_container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;

  @media ($breakpoint_XL) {
    padding: 0 40px;
  }

  @media ($breakpoint_LG) {
    padding: 0 30px;
  }

  @media ($breakpoint_MD) {
    padding: 0 18px;
  }

  @media ($breakpoint_SM) {
    padding: 0 15px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@mixin table_styles {
  .table_section {
    padding: 30px 0px 0px 0px;

    .scrolling_table {
      overflow-x: scroll;
      overflow-y: scroll;

      .scroll_under {
        width: 100%;
        // height: 306px;

        .support_table {
          border-collapse: separate;
          border-spacing: 0 0px;

          thead {
            background: var(--primary-color);
            position: sticky;
            top: 0;
            z-index: 1;

            .table_heading_row {
              .table_heading {
                font-size: 16px;
                font-weight: 600;
                padding: 15px;
                background: none;
                color: var(--txt-white);
                white-space: nowrap;

                @media ($breakpoint_SM) {
                  padding: 7px;
                }

                &:first-child {
                  border-radius: 5px 0px 0px 5px;
                }

                &:last-child {
                  border-radius: 0px 5px 5px 0px;
                }
              }
            }
          }

          tbody {
            background: var(--light-blue);

            .table_data_row {
              transition: all 0.2s linear;
              border-bottom: var(--sky-blue);

              &:hover {
                background: var(--light-blue);
              }

              .table_data {
                padding: 15px;
                color: var(--txt-black);
                font-size: 15px;
                font-weight: 500;
                background: transparent;
                white-space: nowrap;

                @media ($breakpoint_SM) {
                  padding: 7px;
                }

                a {
                  text-decoration: none !important;

                  @media ($breakpoint_SM) {
                    padding: 5px 15px;
                  }
                }

                .pic_text {
                  display: flex;
                  gap: 10px;
                  color: var(--txt-black);

                  img {
                    width: 25px;
                  }
                }
              }

              .payment_fee {
                color: var(--primary-color);

                img {
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.acct-follow {
  display: flex;
  gap: 10px;

  .update-btn {
    border: none;
    background-color: var(--primary-color);
    color: var(--txt-white);
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 16px;
  }

  .btn-cancel {
    background: var(--sidebar-bg) !important;
    color: var(--primary-color);
    border: 1px solid var(--sky-blue);
  }
}

// spinner css styles start

.spinner-main {
  width: 100%;
  height: 300px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.spinner-main .spinnerWrap {
  width: 100%;
}

.spinner_ds {
  height: 300px;
}

.spinner-container > div {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin: auto;
  width: 50px;
  height: 50px;
  background: var(--spinner-bg);
}

#spinner5 {
  position: relative;
  animation: deform 2s linear infinite;
}

@keyframes deform {
  0% {
    border-radius: 0%;
    transform: rotate(0deg) scale(1);
  }

  50% {
    border-radius: 100%;
    transform: rotate(180deg) scale(0.8);
  }

  100% {
    border-radius: 0%;
    transform: rotate(360deg) scale(1);
  }
}

.content .spinner-main {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  width: auto;
  position: absolute;
  z-index: 99999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner-main .spinnerWrap {
  width: 100%;
}

.spinner-main .spinnerWrap p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.spinner-main .spinnerWrap p span {
  position: relative;
  top: -4px;
  left: -3px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 3px;
  visibility: hidden;
  margin: 0 2px;
  animation: opecity 1s linear infinite;
  background-color: #000;
}

.spinner-main .spinnerWrap p .dot-1 {
  animation-delay: 0.3s;
}

.spinner-main .spinnerWrap p .dot-2 {
  animation-delay: 0.4s;
}

.spinner-main .spinnerWrap p .dot-3 {
  animation-delay: 0.5s;
}

.spinner-main .spinnerWrap p .dot-4 {
  animation-delay: 0.6s;
}

@keyframes opecity {
  0% {
    visibility: hidden;
  }

  100% {
    background: var(--liner-bg);
    visibility: visible;
  }

  /* 100%{
      visibility: hidden;
    } */
}

// spinner css styles end
.loader_main {
  position: relative;
  z-index: 1;

  .spinner_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: #f2eeeea1;
    background: #a8a8abc7;

    z-index: 99;
    border-radius: 10px;

    .color_ring {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: 100%;
      transform: translate(-50px, -50px);
    }
  }
}

.form-control {
  font-size: 14px;
  width: 100%;
  line-height: 2.5;
  color: var(--txt-black) !important;
  background-color: var(--bodybg);
  border: 1px solid var(--border-color);

  &:focus {
    box-shadow: none;
    border: none;

    border: 1px solid var(--border-color);
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--txt-black) !important;
  }
}

.pagination__2 {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .previous {
    a {
      background: var(--primary-color);
      padding: 4px 12px 9px 12px;
      border-radius: 5px;
      color: var(--txt-white);
    }
  }

  .active {
    color: var(--sky-blue);
  }

  li {
    color: var(--txt-black);

    a {
      border-radius: 5px;
      padding: 4px 12px 10px 12px;
      background: var(--light-blue);
      margin-right: 10px;
    }
  }

  .next {
    a {
      background: var(--primary-color);
      padding: 4px 12px 9px 12px;
      border-radius: 5px;
      color: var(--txt-white);
    }
  }
}

// marquee section work...

.banner-news {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: var(--txt-black);
  p {
    margin-bottom: 0;
  }

  .marquuqqq {
    // background-color: salmon;

    .rfm-marquee {
      min-width: 80% !important;
    }
  }
}

.banner_modal {
  text-align: center;
  color: var(--txt-black);

  .modal-heading {
    font-size: 20px;
    margin: 15px 0;
  }
}



// all color button ring...

.color-ring{
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-section{
    top: 0 !important;
    left: 0 !important;
    height: 20px !important;
    width: 20px !important;
    transform: scale(0.6);
    margin-right: 20px;
  }


}

.outer_top_dashboard{
  display: flex;
  align-items: center;
}