.team-section {
  margin-top: 30px;

  .team-outer {
    padding: 20px;
    background-color: var(--new_card_bg);
    border: 1px solid var(--new_border_color);
    border-radius: 10px;

    .team-inner {

      .panel{
        .serch-form{

          .input-field {
            padding: 6px 12px;
            border: 1px solid var(--border-color);
            font-size: 15px;
            border-radius: 7px;
            outline: none;
            box-shadow: var(--box-shadow);
            background-color: var(--sidebar-bg);
            color: var(--txt-black);
  
            &::placeholder {
              color: var(--txt-black);
            }
  
            @media ($breakpoint_XXXL) {
              font-size: 14px;
            }
          }
          button{
            border: none;
            border-radius: 5px;
            background: var(--sky-blue);
            color: var(--txt-white);
            font-size: 11px;
            padding: 12px 14px;
          }
        }
      }
      .team_row {
        position: relative;
        z-index: 1;
        padding: 10px;
        margin: 0;

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background: var(--team-bg);
          z-index: -1;
          background-size: cover;
          border-radius: 10px;

          background-position: center;
        }

        .team_col {
          @media ($breakpoint_SM) {
            padding: 0px 10px;
          }
        }
      }

      //   padding: 20px;
      .team-sponser {
        border-radius: 7px;
        //  padding: 20px;

        .sponser-head {
          padding: 10px;
          border-radius: 7px 7px 0 0;
          color: var(--primary-color);

          h6 {
            margin: 0;
            font-weight: 600;
          }
        }

        .sponser-content {
          // padding: 15px;

          p {
            font-size: 15px;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 15px;
            border-radius: 10px;
            padding: 10px;
            background: var(--sky-blue);
            margin-bottom: 5px;

            color: var(--txt-white);
            word-break: break-all;

            @media ($breakpoint_450) {
              gap: 10px;
            }

            svg {
              font-size: 23px;
              color: var(--txt-white);
              transition: all 0.4s linear;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .team-head-content {
        // &::after {
        //     position: absolute;
        //     content: '';
        //     z-index: -1;
        //     background-image: url("../Image/waveh701.svg");
        //     background-size: cover;
        //     background-repeat: no-repeat;
        //     width: 100%;
        //     height: 100%;
        //     top: 0;
        //     right: 0;
        //     filter: drop-shadow(2px 4px 6px black);
        // }
        @media ($breakpoint_XXL) {
          flex-direction: column;
          align-items: normal;
          justify-content: center;
          gap: 10px;
        }

        @media ($breakpoint_LG) {
          flex-direction: column-reverse;
          margin-top: 10px;
        }

        .team-tabs {
          .react-tabs__tab-list {
            border: none;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin: 0;

            @media ($breakpoint_MD) {
              justify-content: flex-start;
            }

            @media ($breakpoint_SM) {
              // justify-content: start;
              padding: 0px;
            }

            .react-tabs__tab--selected {
              border: none;
              background-color: var(--primary-color) !important;
              color: var(--txt-white) !important;
              border-radius: 5px;
              font-size: 16px;
            }

            .react-tabs__tab {
              border: none;
              border-radius: 5px;
              background-color: var(--sky-blue);
              font-size: 15px;
              color: var(--txt-white);
              padding: 8px 14px;

              @media ($breakpoint_XXXL) {
                padding: 12px 14px;
                font-size: 11px;
              }

              @media ($breakpoint_ESM) {
                padding: 12px 11px;
              }
            }

            .react-tabs__tab:focus:after {
              display: none;
            }
          }
        }

        .team-search {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          margin-bottom: 30px;

          @media ($breakpoint_MD) {
            justify-content: flex-start;
            margin-bottom: 20px;
          }

          @media ($breakpoint_SM) {
            flex-direction: column;
          }

          .serch-form {
            display: flex;
            gap: 10px;

            @media ($breakpoint_450) {
              flex-direction: column;
            }

            #names {
              padding: 6px 12px;
              border: 1px solid var(--border-color);
              font-size: 15px;
              border-radius: 7px;
              outline: none;
              box-shadow: var(--box-shadow);
              background-color: var(--sidebar-bg);
              color: var(--txt-black);

              &::placeholder {
                color: var(--txt-black);
              }

              @media ($breakpoint_XXXL) {
                font-size: 14px;
              }
            }

            .level {
              border: 1px solid var(--border-color);
              font-size: 15px;
              border-radius: 7px;
              background-color: var(--sidebar-bg);
              color: var(--txt-black);
              appearance: auto;
              box-shadow: var(--box-shadow);

              @media ($breakpoint_XXXL) {
                font-size: 14px;
              }
            }
            .search-btn{
              border: none;
              border-radius: 5px;
              background: var(--sky-blue);
              color: var(--txt-white);
              font-size: 11px;
              padding: 12px 14px;
            }
          }

          .form-btn {
            display: flex;
            gap: 10px;

            .searchicons {
              background-color: var(--sky-blue);
              color: var(--txt-white);
              padding: 6px 8px;
              border: none;
              outline: none;
              border-radius: 7px;
            }
          }
        }
      }

      .panel {

        .main-tree{
            .tree{
              .highlight {
                border: 2px solid #28a745;
                background-color: #d4edda;
              }
              
            }
        }

        .react-tabs__tab-panel {
          @include table_styles;
        }
      }
    }
  }
}


.dropdown-sec {
  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    margin-bottom: 8px;
    white-space: nowrap;
span {
  color: #24a4e3;
}
  }
}