.presentation_main {
  margin-top: 30px;
  padding: 20px;
  background: var(--new_card_bg);
  border: 1px solid var(--new_border_color);
  border-radius: 10px;

  @media ($breakpoint_SM) {
    padding: 20px 0px;
  }

  .present_top_sec {
    h4 {
      background: var(--light-blue);
      border: 1px solid var(--new_border_color);
      padding: 15px;
      border-radius: 10px;
      color: var(--primary-color);
    }
  }

  .present_inner {
    .present_row {
      margin-top: 30px;

      .present_card {
        box-shadow: var(--box-shadow);
        position: relative;
        z-index: 1;
        margin-bottom: 25px;
        background: var(--new_card_bg);
        border: 1px solid var(--new_border_color);

        .dark-logo {
          display: none;
        }

        .card-body {
          display: flex;
          justify-content: space-between;

          .image_logo {
            align-self: center;

            img {
              width: 75%;
              background: var(--new_card_bg);
              padding: 10px;
              border-radius: 10px;
              box-shadow: var(--box-shadow);

              @media ($breakpoint_XXL) {
                width: 100%;
              }

              @media ($breakpoint_LG) {
                width: 75%;
              }

              @media ($breakpoint_MD) {
                width: 100%;
              }

              @media ($breakpoint_SM) {
                width: 75%;
              }

              @media ($breakpoint_ESM) {
                width: 100%;
              }
            }

            .down_icon {
              position: absolute;
              left: 10px;
              top: 10px;
              background-color: var(--primary-color);
              border-radius: 50%;
              font-size: 34px;
              padding: 5px;
              color: var(--txt-white);
              cursor: pointer;
            }
          }

          .country_sec {
            padding: 0px 20px;

            h6 {
              color: var(--sky-blue);
            }
          }
        }
      }
    }
  }
}

body[data-theme="dark"] {
  .dark-logo {
    display: inline-block !important;
  }

  .light-logo {
    display: none;
  }
}
