.app-sidebar {
    --sidebar-width: 280px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    color: #8a98ac;
    width: var(--sidebar-width);
    height: 100vh;
    z-index: 11;
    background: var(--sidebar-bg);
    border-right: 1px solid var(--sidebar-border-new) !important;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    -o-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    border-right: 0 ;


    @media($breakpoint_XL) {
        top: 0;
        bottom: 0;
        height: 100vh;
        left: -290px;
        border-radius: 0 !important;
    }

    .sidbar-overly {
        display: none;
        width: var(--sidebar-width);
        -webkit-transition: left 0.3s ease, width 0.3s ease;
        -o-transition: left 0.3s ease, width 0.3s ease;
        transition: left 0.3s ease, width 0.3s ease;

    }


    .app-sidebar__logo {
        padding: 15px 15px;
        border-bottom: 1px solid var(--sidebar-border-new);
        text-align: center;
        width: var(--sidebar-width);
        border-radius: 0px;
        // background: var(--sidebar-bg);
        background-color: #000;
        -webkit-transition: left 0.3s ease, width 0.3s ease;
        -o-transition: left 0.3s ease, width 0.3s ease;
        transition: left 0.3s ease, width 0.3s ease;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;



        @media ($breakpoint_XL) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        
        .close-btns {
            display: none;
            color: var(--txt-black);

            @media ($breakpoint_XL) {
                display: inline-block;
                background-color: #ddd;
                border: 0;
                padding: 0;
                outline: 0;
                box-shadow: unset;
            }

        }

        .header-brand {

            @media ($breakpoint_XL) {
                .mode {
                    img {
                        // margin-left: 0;
                    }
                }

            }


            .header-brand-img {
                height: 55px;
                vertical-align: bottom;
                margin-right: 0;
                width: auto;
            }
        }

        .dark-logo {
            display: none;
        }
    }

    .app-sidebar3 {
        // padding-top: 50px;
        background: var(--sidebar-bg);
        z-index: 11;
        position: relative;
        height: 100%;
        overflow: hidden;
        // border-radius: 10px;

        @media ($breakpoint_XL) {
            border-radius: 0;
        }

        .sidebar {
            height: 100%;
            overflow: hidden;

            .sidebar_menu {
                margin-bottom: 0;
                padding: 20px;
                height: calc(90vh - 10px);
                overflow-y: scroll !important;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                scroll-behavior: smooth;

                @media ($breakpoint_MD) {
                    height: calc(80vh - 10px);

                }


                .accordion {
                    .accordion-item {
                        margin: 10px 0px;
                        border: none;
                        background: var(--sidebar-bg);

                        .accordion-header {
                            .accordion-button {
                                padding: 0px;
                                background-color: transparent;
                                box-shadow: none;

                                a,
                                span {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    text-decoration: none;
                                    width: 100%;
                                    padding: 15px 15px;
                                    color: var(--txt-black);
                                    font-size: 15px;
                                    font-weight: 500;
                                    border-radius: 10px;


                                    svg {
                                        color: var(--primary-color);


                                        font-size: 20px;


                                    }
                                }

                                .drop_link {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    text-decoration: none;
                                    width: 100%;
                                    padding: 15px 15px;
                                    color: var(--txt-white);
                                    font-size: 15px;
                                    font-weight: 500;

                                    svg {
                                        color: var(--txt-white);
                                        font-size: 20px;
                                    }
                                }

                                .active_Link {
                                    background-color: var(--primary-color);
                                    border-radius: 10px;
                                }

                                &:hover {
                                    background-color: var(--light-blue);
                                    border-radius: 10px;

                                }

                                &::after {
                                    margin-right: 15px;
                                    color: var(--txt-black);
                                    background-image: var(--arrow-img);
                                }
                            }
                        }

                        .only_button {
                            .accordion-button {
                                padding: 0px;

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .accordion-body {
                            padding: 10px;

                            ul {
                                padding-left: 0px;
                                list-style: none;

                                li {
                                    margin: 5px 0px 5px 25px;

                                    a {
                                        text-decoration: none;
                                        background-color: transparent;
                                        width: 100%;
                                        display: flex;
                                        padding: 10px;
                                        color: var(--txt-black);
                                        font-size: 14px;
                                        border-radius: 4px;
                                        font-weight: 500;
                                        opacity: .7;

                                        &:hover {

                                            color: var(--sky-blue);

                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }


}



.show-index-bar {
    --sidebar-overly: #ddddddab;


    .sidbar-overly {
        display: block;
        background-color: var(--sidebar-overly);
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100vh;
        z-index: -1;

    }


    .app-sidebar {
        left: 0;
    }
}

body[data-theme="dark"] {
    .dark-logo {
        display: inline-block !important;
    }

    .light-logo {
        display: none;
    }
}