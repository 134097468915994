// https://corevisionfinancialadvisory.com/site_assets/images/footer2.jpg

.container-index {
    max-width: 1230px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

.section-index {
    background-color: #fff;
}


// banner section work..... 
.section-banner {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: url(https://earn.world/images/banner-mask.png);
        background: url(../homepage/banner-overly.png);
        background-repeat: repeat;
        // background-size: c ;
        // animation: move-banner 1s linear infinite;
    }


    @keyframes move-banner {

        0% {
            transform: translateY(0);
        }

        70% {
            transform: translateY(20%);
            opacity: 0.4;
        }

    }


    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: #ddd;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        opacity: 0.6;
    }


    .coin-sec {
        position: absolute;
        bottom: 25%;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: -1;
        padding: 0 30px;

        .animation-span {
            position: relative;
            display: block;
            width: 100px;
            height: 100px;
            background-image: url(../Icon/bitcoin-btc-logo.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
            // animation: mover-coin 0.4s linear infinite;
            transition: all 0.4s linear;
        }

        @keyframes mover-coin {
            0% {
                transform: translateY(0);
            }

            80% {
                transform: translateY(8px);
            }
        }

        .coin-1 {
            transform: translateY(-100px);
        }

        .coin-3 {
            transform: translateY(-400px);
        }



    }



    // .coin-right-1{
    //     top: 20%;
    //     right: 30%;
    // }

    // .coin-right-2{
    //     top: 50%;
    //     right: 7%;
    // }

    // .coin-right-3{
    //     bottom: 6%;
    //     right: 20%;
    //     opacity: 0.7;
    // }


    .container-banner {
        margin-bottom: 140px;
        padding: 0 15px;
        width: 100%;

        .banner-top-continer {
            max-width: 760px;
            margin: auto;
            width: 100%;

        }



        .web-logo {
            width: 100%;
            max-width: 200px;
            padding: 10px;
            margin-bottom: 10px;
            animation:  names 1s linear infinite;
            margin: auto;

            img{
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 3vw;
            font-family: $font_heading;

            @media ($breakpoint_MD) {
                font-size: 40px;

            }


        }

        p {
            margin: 10px 0;
            font-family: $font_pragph;
        }

        .btn {
            background-color: var(--primary-color);
            padding: 10px 70px;
            color: var(--txt-white);
            border: 1px solid transparent;
            border-radius: 20px;
            margin-top: 20px;
            transition: all 0.4s linear;
            font-family: $font_btn;


            &:hover {
                color: var(--primary-color);
                border-color: currentColor;
                background-color: transparent;
            }
        }


        .tradingview-widget-container {
            width: 100%;
            height: 41px;
            background-color: #fff;
            color: #000;

            iframe {
                user-select: none;
                box-sizing: border-box;
                display: block;
                height: 100%;
                width: 100%;
                color: #000;
            }

            .tv-ticker-item-tape__short-name {
                color: #000;
            }

            .tv-ticker-item-tape {
                color: #000 !important;
            }

        }

        .trading-sec {
            max-width: 960px;
            margin: 20px auto;
        }
    }
}

.section-banner-overly {
    background-color: #ffffff78;
    width: 100%;

    .banner-overly {
        max-width: 900px;
        width: 100%;
        margin: auto;
        position: relative;
        top: -100px;
        z-index: 3;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 5px 0px #ddd;
            border-radius: 10px;

        }
    }
}


// section-about work.... 


.section-about {
    width: 100%;
    background-color: #ffffff78;
    padding: 0px 0 100px;
    text-align: center;

    .heading-sec {
        margin-bottom: 30px;

        h3 {
            color: var(--txt-black);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.5vw;
            font-family: $font_heading;

            @media ($breakpoint_MD) {
                font-size: 40px;
            }

            @media ($breakpoint_MD) {
                font-size: 22px;
            }
        }

        p {
            font-family: $font_pragph;
        }
    }

    .row {
        align-items: stretch;
    }


    .sec {
        padding: 20px;
        height: 100%;
        border-radius: 10px;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #eae8e84d;

        img {
            width: 100px;
            margin: 10px auto 30px;
        }

        .stap-sec {
            background-color: var(--primary-color);
            box-shadow: 0px 0px 10px 0px #eae8e84d;
            border-radius: 10px;
            padding: 10px;
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 30px;
            color: #fff;
            font-size: 28px;
        }


        h2 {
            font-size: 22px;
            margin-bottom: 10px;
            font-family: $font_heading;
            font-weight: 600;
        }

        p {
            font-family: $font_pragph;
            font-size: 15px;


        }
    }
}

.section-media {
    max-width: 900px;
    width: 100%;
    margin: auto;
    overflow: hidden;

    h3 {
        color: var(--txt-black);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.5vw;
        font-family: $font_heading;

        @media ($breakpoint_MD) {
            font-size: 40px;
        }

        @media ($breakpoint_MD) {
            font-size: 22px;
        }

    }

    .media-card-sec {
        width: 200px;
        margin: 0 auto 20px;


        img {
            width: 100%;
        }
    }

}

.section-wallet {
    padding: 100px 0 0;
    background-color: #fff;

    .wallet-card {
        background-color: var(--primary-color);
        padding: 50px 30px;
        border-radius: 10px;
        color: var(--txt-white);
        background-image: url(https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/fun-fact-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        h2 {
            margin-bottom: 20px;
            font-size: 33px;
        }

        li {
            font-size: 22px;
            margin: 10px 0;
        }

        .buttons {

            a {
                height: 50px;
                width: 150px;
                overflow: hidden;


                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.section-crypto {
    padding: 100px 0;

    .card-heading {
        text-align: center;
        margin-bottom: 40px;

        h1 {
            color: var(--txt-black);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.5vw;
            font-family: $font_heading;

            @media ($breakpoint_MD) {
                font-size: 40px;
            }

            @media ($breakpoint_MD) {
                font-size: 22px;
            }
        }

        p {
            font-family: $font_pragph;

        }
    }

    .card {
        padding: 30px 20px;
        border-color: #ddd;

        .img-crypto-sec {
            width: 100%;
            position: relative;
            z-index: 1;

            img {
                width: 70%;
                box-shadow: 60px 60px 45px 0px rgba(45.000000000000014, 57.000000000000014, 89.00000000000006, 0.09);

            }

            .images2 {

                .img2 {
                    background-color: saddlebrown;
                    margin-top: 20px;
                }

            }

            .crypto-img-3 {
                width: 100%;
            }

            .crypto-img-4 {
                width: 100%;
            }

            .btn {
                background-color: var(--primary-color);
                position: absolute;
                bottom: 15%;
                padding: 9px;
                left: 8%;
                display: block;
                width: 84%;
                color: #fff;
                border: 1px solid transparent;
                transition: all 0.4s linear;

                &:hover {
                    color: var(--primary-color);
                    border-color: currentColor;
                    background-color: #f5faff;
                }
            }

            .text-crypto-sec {
                background-color: sandybrown;
                padding: 10px 30px 10px 10px;
            }


        }

        h5 {
            margin-bottom: 20px;
            font-family: $font_heading;
            color: var(--txt-black);

            @media ($breakpoint_MD) {
                margin-top: 20px;
            }
        }

        p {
            font-family: $font_pragph;
        }

    }
}


.section-details {
    padding: 50px 0 100px;
    background-color: #fff;
    position: relative;
    z-index: 1;

    .sec-heading {
        padding: 0 50px;
        text-align: center;
        margin-bottom: 100px;

        @media ($breakpoint_MD) {
            padding: 0;
        }

        h4 {
            color: var(--txt-black);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.5vw;
            font-family: $font_heading;

            @media ($breakpoint_MD) {
                font-size: 40px;
            }

            @media ($breakpoint_MD) {
                font-size: 22px;
            }
        }

        p {
            font-family: $font_pragph;
        }
    }

    .sec-1 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 30px;
        margin-bottom: 50px;
        position: relative;
        z-index: 1;
        background-color: snow;
        padding: 15px;

        @media ($breakpoint_SM) {
            flex-direction: column;
        }


        .svg-main {
            position: absolute;
            left: 130px;
            top: 75px;
            z-index: -9;

            @media ($breakpoint_MD) {
                display: none;
            }
        }

        .svg-main-2 {
            position: absolute;
            left: 130px;
            bottom: 90px;
            z-index: -9;

            @media ($breakpoint_MD) {
                display: none;
            }
        }


        .sec-img {
            width: 20%;
            height: 100%;

            @media ($breakpoint_SM) {
                margin-bottom: 10px;
            }

            span {
                display: block;
                background-color: var(--primary-color);
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
                margin-top: 10px;
                border-radius: 10px;
                color: #fff;
                box-shadow: 60px 60px 45px 0px rgba(45, 57, 89, 0.09);

            }
        }

        .sec-text {
            background-color: snow;

            h4 {
                font-family: $font_heading;
                color: var(--primary-color);
            }

            p {
                font-family: $font_pragph;
            }

        }
    }

    .sec-1-reverse {
        flex-direction: row-reverse;

        @media ($breakpoint_SM) {
            flex-direction: column;
        }
    }



    .chart-sec-img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        img {
            margin: auto;
            width: 100%;
            height: 100%;
        }
    }


}

.section-partner {
    background-color: #ffffff78;
    padding: 100px 0;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 80%;
        width: 100%;
        background-image: url(../homepage/bg-overly.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    h2 {
        max-width: 900px;
        width: 100%;
        margin: 0 auto 40px;
        color: var(--txt-black);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.5vw;
        font-family: $font_heading;

        @media ($breakpoint_MD) {
            font-size: 40px;
        }

        @media ($breakpoint_SM) {
            font-size: 22px;
        }

    }

    .blockchain-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 900px;
        gap: 20px;
        width: 100%;
        margin: auto;




        a {
            max-width: 330px;
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
            // border-radius: 50%;
            overflow: hidden;
            background-color: var(--primary-color);
            padding: 0 !important;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            // border-top: 4px solid var(--primary-color);
            transition: all 0.4s linear;

            &:hover,
            &:focus {
                background-color: transparent;
                transform: translateY(5px);
            }


            img {
                width: 100%;
            }
        }

    }



    .exchange-sec {

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            list-style-type: none;

            // li {
            //     &:first-child,
            //     &:last-child{
            //         -webkit-transform: translateY(-100px);
            //         -ms-transform: translateY(-100px);
            //         transform: translateY(-100px);
            //     }
            // }
        }

        a {
            height: auto;
            display: block;
            margin: auto;
            // border-radius: 50%;
            overflow: hidden;
            // background-color: var(--primary-color);
            background-color: #fff;
            padding: 0 !important;
            // border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            transition: all 0.4s linear;

            &:hover,
            &:focus {
                background-color: transparent;
                transform: translateY(5px);
            }


            img {
                width: 100%;
            }
        }

    }




}




.section-download {
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 700px;
    display: flex;
    align-items: center;
    width: 100%;


    @media ($breakpoint_SM) {
        height: 500px;
    }



    .slider-all-sec {
        display: block;
        width: 100%;
        height: 250px;
    }


    .dowanload_slider {

        .downlist {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            overflow: hidden;
            transform: rotate(-2deg);



            .text_block {
                display: flex;
                align-items: center;
                padding: 10px 0;
                font-weight: bold;
                font-size: 2.5vw;
                font-family: $font_heading;
            }

            .text_block span {
                color: #ddd8cf;
                font-size: 50px;
                font-weight: 700;
                line-height: 1;

                @media ($breakpoint_SM) {
                    font-size: 25px;

                }
            }
        }


    }

    .slider-sec {
        background-color: #000;
        transform: rotate(-2deg);


        .swiper-slide {

            .text_block {
                display: flex;
                align-items: center;
                padding: 10px 0;
                font-weight: bold;
                font-family: $font_heading;
            }

            .text_block span {
                color: #ddd8cf;
                font-size: 50px;
                font-weight: 700;
                line-height: 1;

                @media ($breakpoint_SM) {
                    font-size: 25px;
                }


            }

        }

    }

    .img-sec-slider {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 550px;
        // height: 500px;
        transform: translate(-50%, -50%);

        @media ($breakpoint_MD) {
            width: 70%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200%;
            height: 200%;
            background: url(../homepage/yellow_dotes.png);
            z-index: -1;
            opacity: 0.4;

            @media ($breakpoint_MD) {
                width: 70%;
                height: 70%;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }

        .buttons {
            margin: 20px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;

            .btn {
                width: auto;
                height: 55px;
                padding: 0;
                background-color: var(--primary-color);

                @media ($breakpoint_MD) {
                    height: 40px;
                }

                @media ($breakpoint_SM) {
                    height: 40px;
                }
            }
        }
    }
}

.coins-section {
    background-color: #fafafe;
    padding: 70px 0 60px;
    z-index: 1;
    position: relative;
    overflow: hidden;

    .heading-sec {
        text-align: center;
        margin-bottom: 40px;

        h3 {
            max-width: 900px;
            width: 100%;
            margin: 0 auto 40px;
            color: var(--txt-black);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.5vw;
            font-family: $font_heading;

            @media ($breakpoint_MD) {
                font-size: 40px;
            }

            @media ($breakpoint_SM) {
                font-size: 22px;
            }
        }
    }

    .row-outer-sec {
        mask-image: linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%);
        padding: 0 20px;
        cursor: col-resize;
        user-select: none;

        .col-12 {
            margin-bottom: 30px;
        }

        .left-sec {

            .coin-box {
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                border-radius: 30px;
                border: 0.5px solid var(--primary-color);
                box-shadow: 0px 0px 2px 0px #ddd;
                width: 100%;
                cursor: col-resize;
                user-select: none;
                box-shadow: unset;
                overflow: hidden;

                img {
                    width: 30px;

                    @media ($breakpoint_SM){
                        width: 15px;
                    }

                }

                .coin-box-text {
                    margin-left: 10px;
                    white-space: nowrap;

                    @media ($breakpoint_SM){
                        margin-left: 5px;
                    }


                    p {
                        white-space: nowrap;

                        font-weight: bold;
                        font-size: 16px;
                        font-family: $font_heading;
                        margin-bottom: 0;

                        @media ($breakpoint_SM){
                            font-size: 12px;
                        }
                    }
                }

            }

        }

        .right-sec {
            margin-left: 50px;

            @media ($breakpoint_SM){
                margin-left: 0;
            }

            .coin-box {
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                border-radius: 30px;
                border: 0.5px solid var(--primary-color);
                box-shadow: 0px 0px 2px 0px #ddd;
                width: 100%;
                flex-direction: row-reverse !important;
                overflow: hidden;

                img {
                    width: 30px;

                    @media ($breakpoint_SM){
                        width: 15px;
                    }

                }

                .coin-box-text {
                    margin-left: 10px;
                    white-space: nowrap;

                    @media ($breakpoint_SM){
                        margin-left: 5px;
                    }

                    p {
                        white-space: nowrap;
                        font-weight: bold;
                        font-size: 16px;
                        font-family: $font_heading;
                        margin-bottom: 0;

                        @media ($breakpoint_SM){
                            font-size: 12px;
                        }
                    }
                }

            }
        }

    }
}

// .homepage_modal {
//     border-radius: 10px;
//     border: 1px solid var(--border-color) !important;
//     box-shadow: var(--box-shadow) !important;

//     .banner_img {
//         width: 100%;
//     }

//     .react-responsive-modal-closeButton {
//         background: var(--light-blue);
//         border-radius: 5px;

//         &:focus {
//             outline: none;
//         }

//         @media ($breakpoint_SM) {

//             top: 6px;
//             right: 5px;
//         }

//         svg {
//             fill: var(--txt-black);

//             @media ($breakpoint_SM) {
//                 width: 20px;
//             }
//         }
//     }

// }