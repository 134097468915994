.app-header {
  width: auto;
  border-radius: 10px;
  display: flex;
  z-index: 10;
  padding: 15px;
  margin: 20px 30px 0px 322px;
  // background: #0F101E;
  background: var(--new_card_bg);
  padding-right: 20px !important;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--new_border_color);

  @media ($breakpoint_XL) {
    justify-content: space-between;
    padding: 15px 0px 14px 0px;
    margin: 0px;
    border-radius: 0px;
  }

  .right-header-sec {
    display: flex;
    gap: 15px;
    align-items: center;

    .mode {
      .dark {
        cursor: pointer;
        color: var(--txt-white);
      }

      .light {
        cursor: pointer;
        color: var(--txt-light);
      }
    }

    .log_button {
      svg {
        background: var(--txt-white);
        color: var(--sky-blue);
        padding: 4px;
        border-radius: 50%;
        margin-left: 11px;
        font-size: 25px;
      }
    }

    .icons_im {
      input {
        border: none;
      }

      img {
        width: 38px;
      }
    }

    .ring-icon {
      margin-right: 10px;
    }

    .log-btn {
      border-radius: 10px;
      background: var(--primary-color);
      padding: 4px 12px;
      color: var(--txt-white);
      font-size: 16px;
      border: none;
    }
  }
}

.for_toggle_scan {
  @media ($breakpoint_XL) {
    display: flex;
    gap: 10px;
  }

  .app-sidebar__toggle {
    -webkit-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    z-index: 1;
    margin-left: 20px;
    display: none;

    img {
      width: 54px;
      display: none;

      @media ($breakpoint_XL) {
        display: block;
      }
    }

    @media ($breakpoint_XL) {
      display: flex;
    }

    svg {
      fill: var(--primary-color);
      height: 30px;
      width: 30px;
    }

    &:focus {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link-copy {
      display: flex;
      align-items: center;
      margin-right: 10px;

      input {
        font-size: 14px;
        margin-bottom: 0px;
        padding: 10px;
        border-radius: 5px 0 0 5px;
        width: 300px;
        border: 1px solid var(--border-color);
        background: var(--bodybg);
        color: var(--txt-black);
      }

      .icon-copy {
        background: var(--sky-blue);
        padding: 4px 11px;
        border: 1px solid var(--border-color);
        border-radius: 0 5px 5px 0;
        border-left: none;

        svg {
          font-size: 32px;
          color: var(--txt-white);
          cursor: pointer;
        }
      }
    }

    @media ($breakpoint_MD) {
      display: none;
    }

    p {
      font-size: 14px;
      margin-bottom: 0px;
      background: var(--bodybg);
      padding: 10px;
      border-radius: 5px;
      // width: 80%;
      border: none;
      margin-right: 10px;

      span {
        svg {
          color: var(--sky-blue);
        }
      }
    }

    .scan_code {
      width: 38px;
      border: 1px solid var(--sky-blue);
      border-radius: 5px;
    }

    .icons_im {
      display: flex;
      cursor: pointer;

      svg {
        font-size: 38px;
        color: var(--primary-color);
        margin-left: 10px;
      }
    }
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: translateX(-$sidebar_width);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-$sidebar_width);
  }
}

.new_modal_sidebar {
  height: 100vh;
  background-color: var(--sidebar-bg) !important;
  position: fixed !important;
  top: 0;
  left: 0px !important;
  width: $sidebar_width;
  margin: 0px !important;
  transition: 0.5s;
  z-index: 12;
  overflow: auto;
  border-radius: 0px !important;
  border: 0px !important;

  @media ($breakpoint_ESM) {
    width: 260px;
  }

  .responsive_sidebar {
    .sidebar {
      padding: 15px 0px 15px 15px;

      .sidebar_menu {
        margin-bottom: 0;
        padding: 0;

        .logo_sec {
          text-align: center;

          .sidebar_logo {
            text-align: center;

            img {
              width: 70px !important;
              margin-bottom: 10px;
            }
          }
        }

        .accordion {
          .accordion-item {
            margin: 10px 0px;
            border: none;

            .accordion-header {
              background-color: var(--sidebar-bg);

              .accordion-button {
                padding: 0px;
                background-color: var(--sidebar-bg);
                box-shadow: none;

                a,
                span {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  text-decoration: none;
                  width: 100%;
                  padding: 15px 15px;
                  color: var(--txt-black);
                  font-size: 15px;
                  font-weight: 500;

                  svg {
                    color: var(--primary-color);
                    font-size: 20px;
                  }
                }

                .drop_link {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  text-decoration: none;
                  width: 100%;
                  padding: 15px 15px;
                  color: var(--txt-white);
                  font-size: 15px;
                  font-weight: 500;

                  svg {
                    color: var(--txt-white);
                    font-size: 20px;
                  }
                }

                .active_Link {
                  background-color: var(--primary-color);
                  border-radius: 50px 0px 0px 50px;
                }

                &:hover {
                  background-color: var(--light-blue);
                  border-radius: 50px 0px 0px 50px;
                }

                &::after {
                  margin-right: 15px;
                  background-image: var(--arrow-img);
                }
              }
            }

            .only_button {
              .accordion-button {
                padding: 0px;

                &::after {
                  display: none;
                }
              }
            }

            .accordion-body {
              padding: 10px;
              background-color: var(--sidebar-bg);

              ul {
                padding-left: 0px;
                list-style: none;

                li {
                  margin: 5px 0px;

                  a {
                    background-color: transparent;
                    width: 100%;
                    display: flex;
                    padding: 10px;
                    color: var(--txt-black);
                    font-size: 14px;
                    border-radius: 4px;
                    font-weight: 500;
                    opacity: 0.7;
                    text-decoration: none;
                    margin-left: 27px;

                    &:hover {
                      // background-color: var(--sidebar-hover);
                      color: var(--sky-blue);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .react-responsive-modal-closeButton {
    top: 8px;
    position: absolute;
    right: 8px;
    background-color: transparent;
    border: none;

    &:focus-visible {
      outline: none !important;
    }

    svg {
      fill: var(--txt-black);
    }
  }
}

.qr_modal {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding: 20px !important;
  background-color: var(--sidebar-bg) !important;

  .scan_modal {
    width: 200px;

    border-radius: 5px;
  }

  .react-responsive-modal-closeButton {
    svg {
      display: none !important;
    }
  }
}
