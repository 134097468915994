// @import 'bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap';
@import './_theme.scss'; // Only css variable ( color acc useing__ )...
@import './Common.scss'; // Common scss ( breakpoints , mixin, common button , common list, common Heading size and much more )
@import './sidebar.scss';
@import './profile1.scss';
@import './deposit.scss';
@import './trading.scss';
@import './team.scss';
@import './overview.scss';
@import './login.scss';
@import './header.scss';
@import './footer.scss';
@import './dashboard.scss';
@import './buyinfra.scss';
@import './unilevel.scss';
@import './presentation.scss';
@import './support.scss';
@import './ticket.scss';
@import './payoutrequest.scss';


// ? Please Login and signUp more page ( New create new scss file then import_)

@import './index.scss';



.bg-chart-card {
    background-color: var(--sidebar-bg) !important;
}

.chart_style {
    svg {
        rect {
            background-color: transparent !important;
            fill: transparent !important;
        }

        g {

            text {
                fill: var(--txt-black) !important;
            }
        }
    }
}



.scrolling_table_new {
    overflow-x: scroll;
    overflow-y: scroll;


    

    .table-sec{

        @media ($breakpoint_MD){
            width: 150%;

        }
    }
}
.react-responsive-modal-modal{
     border-radius: 10px;
     @media($breakpoint_LG){
        width: 420px;
     }
     @media($breakpoint_SM){
        width: 350px;
     }
     @media($breakpoint_ESM){
        width: 310px;
     }
     @media($breakpoint_50){
        width: 280px;
     }
}
.vista_sec{
    input{
        width: 100%;
        border-radius: 6px;
        padding: 7px;
        border: 1px solid grey !important;
        &:focus{
            outline: none;
        }
    }
  
    .stake_btn{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
      
    }
    p{
        margin-bottom: 4px;
    }
 
}