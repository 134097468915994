.request_main {
  margin-top: 30px;

  @media ($breakpoint_ESM) {
    padding: 20px 0px;
  }

  .request_inner {
    padding: 20px;
    background: var(--new_card_bg);
    border: 1px solid var(--new_border_color);

    border-radius: 10px;

    h4 {
      border-radius: 10px;
      padding: 15px;
      border: 1px solid var(--new_border_color);
      background: var(--light-blue);
      color: var(--primary-color);
    }

    .react-tabs {
      padding: 30px;
      // background: var(--bodybg);
      border-radius: 10px;
      margin-top: 30px;

      @media ($breakpoint_SM) {
        padding: 0px;
      }

      .react-tabs__tab-list {
        border: none;
        justify-content: center;
        display: flex;
        margin-bottom: 24px;
        position: relative;
        z-index: 1;

        @media ($breakpoint_SM) {
          display: block;
        }

        &::before {
          content: "";
          position: absolute;
          width: 86%;
          height: 1px;
          left: 45px;
          top: 30px;
          z-index: -1;
          border: 1px dashed var(--txt-light);
          display: none;

          @media ($breakpoint_MD) {
            left: 16px;
          }

          @media ($breakpoint_SM) {
            display: none;
          }
        }

        .react-tabs__tab {
          background: transparent;
          border: none;
          text-align: center;
          position: relative;
          z-index: 1;

          @media ($breakpoint_SM) {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 5px;
          }

          &:focus {
            &::after {
              display: none;
            }
          }

          .number {
            .number_style {
              background: var(--sky-blue);
              padding: 4px 16px;
              font-size: 32px;
              border-radius: 50%;
              color: var(--txt-white);

              @media ($breakpoint_SM) {
                padding: 4px 9px;
                font-size: 16px;
              }
            }

            .number_style_1 {
              background: var(--sky-blue);
              padding: 7px 18px;
              font-size: 32px;
              border-radius: 50%;
              color: white;

              @media ($breakpoint_SM) {
                padding: 4px 11px;
                font-size: 16px;
              }
            }
          }

          .text_head {
            color: var(--sky-blue);
            font-weight: 600;
            line-height: 1.5;
            font-size: 16px;
            margin-top: 20px;
            background: var(--bodybg);
            padding: 10px;

            clip-path: polygon(
              50% 0%,
              103% -7%,
              100% 38%,
              106% 103%,
              75% 88%,
              49% 98%,
              24% 89%,
              -14% 106%,
              0% 35%,
              -21% -19%
            );

            @media ($breakpoint_MD) {
              font-size: 12px;
            }

            @media ($breakpoint_SM) {
              margin-top: 0px;
              font-size: 15px;
            }
          }
        }

        .react-tabs__tab--selected {
          position: relative;
          z-index: 1;

          .number {
            .number_style {
              background: var(--primary-color);
            }

            .number_style_1 {
              background: var(--primary-color);
            }
          }

          .text_head {
            color: var(--primary-color);
            font-weight: 600;
            line-height: 1.5;
            font-size: 16px;
            margin-top: 20px;
            background: var(--light-blue);
            padding: 10px;

            clip-path: polygon(
              50% 0%,
              103% -7%,
              100% 38%,
              106% 103%,
              75% 88%,
              49% 98%,
              24% 89%,
              -14% 106%,
              0% 35%,
              -21% -19%
            );

            @media ($breakpoint_MD) {
              font-size: 12px;
            }

            @media ($breakpoint_SM) {
              margin-top: 0px;
              font-size: 15px;
            }
          }
        }
      }

      .react-tabs__tab-panel {
        .payout_outer {
          box-shadow: var(--box-shadow);
          border: 1px dotted var(--border-color);

          .panel_inner {
            padding: 20px;
            width: 50%;

            @media ($breakpoint_MD) {
              width: 100%;
            }

            .pay-req {
              display: flex;
              justify-content: space-between;
              background: var(--light-blue);
              margin-bottom: 10px;
              padding: 10px;
              border-radius: 10px;

              h6 {
                margin: 0;
                color: var(--txt-black);
              }
            }
          }
        }

        .panel_section {
          border-radius: 10px;
          margin-top: 30px;

          .income_card {
            border: 1px dotted var(--border-color);
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            position: relative;
            background: var(--new_card_bg);
            z-index: 1;
            border-right: 4px solid var(--primary-color);

            @media ($breakpoint_SM) {
              border-left: 4px solid var(--primary-color);
            }

            &::before {
              content: "";
              position: absolute;
              width: 85px;
              height: 100%;
              border-radius: 10px;
              border: 1px dashed var(--sky-blue);
              left: 0px;
              top: 0px;
              border-radius: 10px 50% 50% 10px;
              z-index: -1;
              background-color: var(--light-blue);

              @media ($breakpoint_SM) {
                display: none;
              }
            }

            .card-body {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .left-sec {
                display: flex;
                align-items: center;
                width: 100%;

                .total_income{
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;

                  div{
                    &:last-child{
                      margin-right: 20px;
                    }
                  }
                }

                svg {
                  background: var(--primary-color);
                  font-size: 51px;
                  color: var(--txt-white);
                  padding: 5px;
                  border-radius: 50%;
                  margin-right: 40px;

                  @media ($breakpoint_SM) {
                    margin-right: 10px;
                  }
                }

                .total {
                  color: var(--txt-light);
                }

                .info {
                  color: var(--primary-color);
                  word-break: break-all;
                  padding-right: 10px;
                }
              }

              .form-select {
                width: auto;

                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }

        .button_sec {
          margin-top: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          .button_style {
            text-decoration: none;
            width: 30%;

            svg {
              font-size: 13px;
              margin-left: 10px;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.request_modal {
  border-radius: 10px;
  width: 350px;
  border: 1px solid var(--sky-blue);
  background-color: var(--sidebar-bg) !important;

  @media ($breakpoint_SM) {
    width: 60%;
  }

  .request_inner {
    padding: 20px 0px;
    text-align: center;

    p {
      color: var(--txt-black);
    }
  }

  .react-responsive-modal-closeButton {
    display: none;
  }
}
