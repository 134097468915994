.ticket_view {
  margin-top: 30px;

  .ticket_inner {
    padding: 20px;
    background: var(--new_card_bg);
    border: 1px solid var(--new_border_color);
    border-radius: 10px;

    h4 {
      background: var(--light-blue);
      border: 1px solid var(--new_border_color);
      padding: 15px;
      border-radius: 10px;
      color: var(--primary-color);
      margin: 0;
    }

    @include table_styles;
  }
}

.image_modal {
  width: 400px;
  border-radius: 10px;
  border: 1px solid var(--sky-blue);
  background: var(--sidebar-bg) !important;

  img {
    width: 100%;
    max-width: 400px;
  }

  @media ($breakpoint_SM) {
    width: auto;
  }

  .react-responsive-modal-closeButton {
    background: var(--light-blue);
    border-radius: 10px;
    padding: 3px 5px;

    &:focus {
      outline: none;
    }

    svg {
      fill: var(--primary-color);
    }
  }
}
