.footer {
  margin: 20px 30px 25px 322px;
  padding: 20px;
  background: 0 0;
  // border-top: 1px solid var(--border-color);
  position: relative;
  bottom: 0px;
  width: auto;
  background: var(--new_card_bg);
  border: 1px solid var(--new_border_color);
  border-radius: 10px;

  @media ($breakpoint_XL) {
    margin: 30px 0px 0px 0px;
    padding: 20px;
    border-radius: 0;
  }

  p {
    margin-bottom: 0px;
    font-size: 15px;
    color: var(--txt-black);

    @media ($breakpoint_ESM) {
      font-size: 15px;
    }

    a {
      color: var(--primary-color);
      text-decoration: none;
    }
  }
}
