.login-body {
  background-color: var(--bodybg);
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;


  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url("../Image/loginh101.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;


    @media ($breakpoint_SM){}
    // 
  }


  .empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .form-row {
      .login-pics {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .waves {
        // position: relative;
        // z-index: 1;
        // height: 100%;

        .form-outer {
          max-width: 500px;
          margin: 0 auto;
          padding: 50px 0px 50px;

          .form-inner {
            background-color: var(--txt-white);
            padding: 50px;
            border-radius: 8px;
            box-shadow: var(--box-shadow);
            background-color: #000;
            color: #fff;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @media (max-width: 400px) {
              padding: 25px;
            }



            .wallet-sec {
              background-color: salmon;
              position: absolute;
              top: -84px;
              right: -84px;
              width: 200px;
              height: 200px;
              z-index: 1;
              border-radius: 70%;
              overflow: hidden;
              padding-right: 80px;
              padding-top: 70px;

              button{
                // display: none;
                width: 100%;
                height: 100%;

              }
            }

            .connecte-btn{
              // animation:  up-down 1s linear infinite;
              margin-top: 10px;
              position: relative;
              z-index: 1;
              overflow: hidden;
              padding: 7px 15px;
              font-size: 14px;

              &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 20px;
                background-color: #ffffffb4;
                filter: blur(10px);
                animation: left-right 1.4s linear infinite;
                z-index: -1;

              }
            }

            @keyframes up-down {
              0%{ transform: translateY(0);}
              50%{ transform: translateY(10px);}
              
            }

            @keyframes left-right {
              0%{ left: 0%}
              50%{ left: 100%}
              
            }


            .form-head {
              text-align: center;
              margin-bottom: 30px;

              h2 {
                font-size: 30px;
                font-weight: 600;
              }

              p {
                font-size: 16px;
                margin: 0;
              }
            }

            .form-start {
              width: 100%;

              #email {
                width: 100%;
                font-size: 16px;
                border: none;
                border-bottom: 2px solid var(--border-color);
                background-color: var(--bg-txt);
                padding: 5px;
                font-family: sans-serif;
                margin-bottom: 25px;
                outline: none;
                padding-right: 0px;
                 background-color: #fff;
                 padding: 10px 15px;
                 border-radius: 10px;


                &:-webkit-autofill {
                  background-color: #fff !important;
                  -webkit-text-fill-color: #000;
                  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                }

                &:focus {
                  box-shadow: 0 0 0px 1000px var(--txt-white) inset;

                  border-color: var(--primary-color);
                }

                &:focus-visible {
                  outline: none;
                }

                &::placeholder {
                  color: #000;
                }
              }

              .hide-pass {
                display: flex;
                align-items: center;
                position: relative;

                button {
                  border: none;
                  font-size: 16px;
                  position: absolute;
                  background: none;
                  right: 0;
                  top: 0;

                  &:focus {
                    outline: none;
                  }

                  svg {
                    font-size: 20px;
                    color: grey;
                  }
                }
              }

              .login-remfor {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;

                .login-check {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  font-size: 15px;

                  @media (max-width: 400px) {
                    font-size: 14px;
                  }
                }

                a {
                  text-decoration: none;
                  color: var(--primary-color);
                  font-size: 16px;

                  @media (max-width: 400px) {
                    font-size: 14px;
                  }
                }
              }

              .log-btn {
                border: none;
                width: 100%;
                margin: auto;
                display: block;
                padding: 14px;
                background-color: var(--primary-color);
                color: var(--txt-white);
                font-size: 15px;
                font-weight: 400;
                border-radius: 8px;


              }

              .form-para {
                margin-top: 25px;
                text-align: center;

                p {
                  font-size: 16px;
                  font-weight: 400;
                }

                a {
                  color: var(--primary-color);
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.register-pg {
  height: 100% !important;
  overflow: hidden !important;
}

.css-b62m3t-container {
  margin-bottom: 30px;
}

.css-13cymwt-control {
  border: 0 !important;
  border-bottom: 2px solid var(--border-color) !important;
  border-radius: 0 !important;
}

// #react-select-3-option-0{
//   background-color: salmon !important;
//   padding: 0 10px !important;
// }

.css-1n6sfyn-MenuList {
  background-color: red($color: #000000);
  padding: 10px 10px !important;
}

.css-t3ipsp-control {
  border: 0 !important;
  border-bottom: 2px solid var(--primary-color) !important;
  box-shadow: unset !important;
  outline: unset !important;
  border-radius: 0 !important;
}