.uni-level-outer {
  margin-top: 30px;

  .box-content {
    margin-bottom: 20px;

    .table-details {
      padding: 15px;

      .card-header {
        padding: 0;
        background-color: transparent;
        border-bottom: 0;
        display: flex;
        align-items: center;

        .card-imgs {
          position: relative;
          z-index: 1;
          display: inline-block;

          .icon {
            position: absolute;
            top: 15px;
            right: 5px;
            font-size: 35px;
            color: var(--primary-color);
            transition: all 0.4s linear;

          }
        }
      }

      &:hover {

        .card-imgs {
          .icon {
            top: 5px;
            right: 13px;
            transform: scale(0.9);

          }
        }

      }

      .de-sc {
        display: inline-block;
        width: 20px;
        margin-right: 20px;
        height: 10px;
        background-color: var(--primary-color);
        margin-left: 20px;
      }

      .users-txt {
        font-size: 15px;
      }

      .active-txt {
        color: var(--primary-color) !important;
      }
    }
  }



  .level_inner {
    padding: 20px;
    background: var(--new_card_bg);
    border: 1px solid var(--new_border_color);
    border-radius: 10px;

    .top_section_unilevel {
      display: flex;
      gap: 15px;
      white-space: nowrap;
      flex-wrap: wrap;
      align-items: center;

      .date {
        position: relative;
        z-index: 1;

        .from {
          position: absolute;
          bottom: 29px;
          left: 11px;
          font-size: 12px;
          background: transparent;
          padding: -1px;
          padding: 3px;
          border-radius: 5px;
          color: var(--txt-light);
        }

        .to {
          position: absolute;
          bottom: 29px;
          left: 14px;
          font-size: 12px;
          background: transparent;
          padding: -1px;
          padding: 3px;
          border-radius: 5px;
          color: var(--txt-light);
        }

        .select_level {
          position: absolute;
          bottom: 26px;
          left: 11px;
          font-size: 12px;
          background: #e2e8f2;
          padding: -1px;
          padding: 3px 5px;
          border-radius: 5px;
          color: var(--txt-light);
        }

        .select_option {
          font-size: 13px;
          padding: 10px;
          background: transparent;
          color: var(--txt-black);
        }

        span {
          color: var(--txt-black);
        }

        input {
          border: 1px solid var(--border-color);
          padding: 3px 10px;
          border-radius: 5px;
          background: transparent;
          color: var(--txt-black);
          font-size: 13px;

          &:focus {
            outline: none;
          }
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
          border-radius: 4px;
          margin-right: 2px;
          opacity: 0.6;
          filter: invert(0.4);
        }
      }

      .level_open {
        margin-bottom: 0px;
        padding: 10px;
        background: var(--bodybg);
        border-radius: 10px;
        color: var(--sky-blue);
        font-size: 14px;

        @media ($breakpoint_450) {
          text-align: center;
          width: 100%;
        }

        span {
          color: var(--primary-color);
          font-weight: 600;
          padding: 5px 10px;
          background: var(--light-blue);
          border-radius: 5px;
          margin-left: 5px;
        }
      }

      .form-control {
        appearance: auto;
        width: auto !important;
        border: 1px solid var(--border-color);

        &:focus {
          box-shadow: none;
          border: 1px solid var(--border-color);
        }

        @media ($breakpoint_450) {
          text-align: center;
          width: 100% !important;
        }
      }

      .form-btn {
        display: flex;
        gap: 10px;

        .searchicons {
          background-color: var(--sky-blue);
          color: var(--txt-white);
          padding: 5px 7px;
          border: none;
          outline: none;
          border-radius: 7px;
        }
      }
    }

    .cash_flow {
      justify-content: space-between;
      padding: 10px;
      border-radius: 10px;
      background: var(--light-blue);
      border: 1px solid var(--new_border_color);

      h4 {
        color: var(--primary-color);
        margin: 0px;
      }

      .level_open {
        background: var(--sidebar-bg);
      }
    }

    .led-selected {
      .ledger-form {
        .ledger {
          width: 240px !important;
          padding: 10px;
          text-align: left;
          border: 1px solid var(--border-color);
          background-color: var(--new_card_bg);
          color: var(--txt-black);

          @media ($breakpoint_50) {
            width: 100% !important;
          }
        }
      }
    }

    .rank-head {
      .rank-data {
        border: 1px solid var(--border-color);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        background: var(--sidebar-bg);

        p {
          margin: 0;
          border-right: 1px solid var(--border-color);
          padding-right: 10px;
          font-size: 16px;

          @media ($breakpoint_ESM) {
            font-size: 14px;
          }
        }

        h5 {
          margin: 0;
          text-align: center;
          padding-left: 10px;
          color: var(--sky-blue);

          @media ($breakpoint_ESM) {
            font-size: 14px;
          }
        }
      }
    }

    .rank-royal {
      color: var(--primary-color);
      margin: 0px;

      @media ($breakpoint_450) {
        font-size: 20px;
      }

      @media ($breakpoint_ESM) {
        font-size: 14px;
      }
    }

    .rank_table-head {
      text-align: center;
    }

    .rank_table-body {
      text-align: center;
    }

    .justi {
      justify-content: center;
    }

    @include table_styles;
  }
}